body {
	transition: all 0.3s;
}

.black {
	background: rgba(0, 0, 0, 0.4);
}
.btn {
	box-shadow: none !important;
}
/* main#main {
	margin-left: 110px;
	margin-left: 0px;
	margin-right: 25px;
	margin-right: 0px;
} */
.main-content{
	margin: 0px 25px 30px;
}

@media screen and (max-width:786px){
.main-content{
	margin: 0px 0px 50px;
}

}

main .content {
	padding: 10px 20px 10px 50px;
	margin-top: 15px;
}

#sidebar {
	width: 85px;
	position: fixed;
	z-index: 1030;
	top: 50%;
	transform: translateY(-50%);
	transition: all 0.5s;
	background-color: #f5f6fb;
	background-color: var(--accent-darker);
	padding: 20px 0 10px;
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: center;
	text-align: center;

	left: 18px;
	left: 0px;
	height: 95%;
	height: 100%;
	border-radius: 20px;
	border-radius: 0px;
}

#sidebar #close {
	padding: 12px;
}

.nav-links {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-decoration: none;
	font-size: 18px;
	padding: 10px 30px;
	color: #282830;
	color: #cfcbcb;
	transition: all 0.3s;
	margin: 10px 0;
	position: relative;
}
.navbar-brand {
	margin: 0;
}

.nav-links .side-nav-text {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	white-space: nowrap;
	padding: 10px 20px;
	border-radius: 3px;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
	z-index: 1000;
	border: 1px solid white;
	color: #fff !important;
	background: var(--accent-darker);
}

#sidebar a.nav-links i {
	transition: 0.3s;
}
#sidebar a.nav-links:hover i {
	transform: scale(1.3);
	color: #fff !important;
}
@media (min-width:600px) {
#sidebar a.nav-links:hover .side-nav-text {
	transform: translate(40px, -50%);
	opacity: 1;
	visibility: visible;
	z-index: 1000 !important;
}
}

@media screen and (max-width:600px){
	.nav-links .side-nav-text {
	position: relative;
	/* top: 50%; */
	font-size: 0.5em;
	transform: translate(0, 0);
	white-space:normal;
	margin:0px 0px;
	padding: 0px;
	border-radius: 0px;
	opacity: 1;
	visibility: visible;
	transition: 0.3s;
	z-index: 00;
	border: 0px;
	color: #ffffff99 !important;
	background:transparent;
}
}
@media screen and (max-width:600px){
	.nav-links {
		margin: 5px 0;
		font-size:1.2em;
	}
}


#close:hover {
	color: #000000;
}

@media screen and (max-height: 450px) {
	#sidebar {
		padding-top: 15px;
	}
	#sidebar a {
		font-size: 18px;
	}
}

.opacity {
	animation-name: opacity;
	animation-timing-function: ease-in-out;
	animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-delay: 0s;
}

@keyframes opacity {
	0% {
		opacity: 0%;
	}
	50% {
		opacity: 50%;
	}
	100% {
		opacity: 100%;
	}
}

#nav-opener {
	transition: all 0.5s;
}
#nav-opener-brand {
	transition: all 0.5s;
}
.hide-btn {
	transform: translateX(-20vw) translateZ(0);
}
#only-mobile-logout {
	display: none;
}

#only-mobile-logout .nav-links:first-of-type {
	border-top: 0;
}

@media screen and (max-width: 991px) {
	main#main {
		margin-left: 0 !important;
	}
	#sidebar {
		transform: translateX(-110px) translateY(-50%);
	}
}
@media screen and (max-width: 991px) {
	main#main {
		width: 100%;
	}
	#sidebar {
		overflow-y:auto !important;
	}
	.hide-btn {
		transform: translateX(-50vw) translateZ(0);
	}
	#nav-opener {
		display: none !important;
	}
	#only-mobile-logout {
		display: block;
	}
	main .content {
		padding: 10px;
	}
}
