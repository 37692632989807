@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

:root {
	--accent-darker: #2c2e3e;
	--accent-dark: #02306d;
	--accent-light: #378cfc;
	--main: #004aad;
	--accent: var(--main);
	--font: #edf6f9;
	--secondary: #e29578;
	--secondary-light: #ffddd2;

	--bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");
}

html,
body {
	min-height: 100vh;
}
body {
	background-color: white;
	/* overflow-y: auto !important; */
}
body * {
	font-family: Poppins, sans-serif;
}

/* width */
::-webkit-scrollbar {
	width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #bbb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--accent-light);
}
/* Handle on focus */
::-webkit-scrollbar-thumb:focus {
	background: var(--accent);
}

a {
	text-decoration: none;
}

a.forgot-password {
	color: #6772e5;
	padding: 0.3rem 0;
	display: inline-block;
	font-size: 0.8rem;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
	transform: translate(14px, -10px) scale(0.75) !important;
	background-color: #fff;
	padding: 0 10px;
	font-size: 20px;
}

input:not([type='checkbox']):not([type='radio']) {
	border: white 2px solid !important;
	border-radius: 3px !important;
	/* background-color: #f2f6f9 !important; */
	color: #6b7c93 !important;
	box-shadow: 0 02px 6px rgba(165, 165, 185, 0.45) !important;
	padding: 6px 10px !important;
	transition: box-shadow 350ms ease !important;
	display: block !important;
	width: 100% !important;
}
input:focus,
input:active {
	border: #f2f6f9 2px solid !important;
	box-shadow: 0 3px 9px 2px rgba(50, 50, 93, 0) !important;
	outline: none !important;
}

.noinputstyle input:not([type='checkbox']):not([type='radio']) {
	border: white 2px solid !important;
	background-color: #fff !important;
	box-shadow: 0px 3px 1px rgba(50, 50, 93, 0.1), 1px -1px 1px rgba(50, 50, 93, 0.1),
		-1px 0px 1px rgba(50, 50, 93, 0.1) !important;
}
.noinputstyle input:not([type='checkbox']):not([type='radio']):focus,
.noinputstyle select:focus {
	background-color: #fff !important;
	box-shadow: 0px 3px 1px #27cf90, 1px -1px 1px rgba(50, 50, 93, 0.1), -1px 0px 1px rgba(50, 50, 93, 0.1) !important;
}
.noinputstyle input:not([type='checkbox']):not([type='radio']):active {
	background-color: #fff !important;
	box-shadow: 0px 3px 1px rgb(78, 150, 233), 1px -1px 1px rgba(50, 50, 93, 0.1), -1px 0px 1px rgba(50, 50, 93, 0.1) !important;
}
.noinputstyle input:active,
.noinputstyle select:active {
	background-color: #f2f6f9 !important;
	box-shadow: 0px 3px 1px rgba(50, 50, 93, 0.1), 1px -1px 1px rgba(50, 50, 93, 0.1),
		-1px 0px 1px rgba(50, 50, 93, 0.1) !important;
}

/* .noinputstyle input[type='date']::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
} */
.is-valid {
	background-image: none !important;
	border-color: #ced4da;
}

select {
	border: white 2px solid !important;
	border-radius: 3px !important;
	background-color: #f2f6f9 !important;
	color: #6b7c93 !important;
	box-shadow: 0 02px 6px rgba(165, 165, 185, 0.45) !important;
	padding: 6px 10px !important;
	transition: box-shadow 350ms ease !important;
	display: block !important;
	width: 100% !important;
}

select:focus,
select:active {
	box-shadow: 0 3px 9px 2px rgba(50, 50, 93, 0.15) !important;
	outline: none !important;
}

.noinputstyle select,
.noinputstyle select:active,
.noinputstyle select:active {
	background-color: white !important;
	box-shadow: 0px 3px 1px rgba(50, 50, 93, 0.1), 1px -1px 1px rgba(50, 50, 93, 0.1),
		-1px 0px 1px rgba(50, 50, 93, 0.1) !important;
}
.noinputstyle select {
	border: white 2px solid !important;
	border-radius: 3px !important;
	background-color: white !important;
	color: #6b7c93 !important;
	padding: 6px 10px !important;
	transition: box-shadow 350ms ease !important;
	display: block !important;
	width: 100% !important;
}

textarea.form-control {
	border: 0;
	border-radius: 4px !important;
	box-shadow: 0 02px 6px rgba(165, 165, 185, 0.45) !important;
}

form button {
	display: inline-block !important;
	letter-spacing: 1px !important;
	padding: 0.5rem 1rem !important;
	border-radius: 5px !important;
	font-weight: 600 !important;
	cursor: pointer !important;
	transition: background-color 0.7s cubic-bezier(0.19, 1, 0.22, 1), transform 0.7s cubic-bezier(0.19, 1, 0.22, 1),
		box-shadow 0.7s cubic-bezier(0.19, 1, 0.22, 1) !important;
	border: none !important;
	background-color: var(--accent) !important;
	color: #fff !important;
	box-shadow: rgba(50, 50, 93, 0.11) 0px 4px 6px 0px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px !important;
}

form button:hover {
	background-color: var(--accent-light) !important;

	transform: translateY(-2px);
	box-shadow: rgba(50, 50, 93, 0.18) 0px 4px 12px 0px, rgba(0, 0, 0, 0.08) 0px 1px 6px 0px !important;
}
form button:active {
	background-color: var(--accent-light) !important;
	transform: translateY(0) !important;
	box-shadow: rgba(22, 22, 41, 0.15) 0px 4px 8px 4px, rgba(0, 0, 0, 0.1) 0px 1px 6px 2px !important;
	outline: none !important;
}
form button:disabled {
	transform: none !important;
	box-shadow: none !important;
	position: relative !important;
	z-index: 1 !important;
	box-shadow: rgba(50, 50, 93, 0.11) 0px 4px 6px 0px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px !important;
	overflow: hidden !important;
}
form button.disabled::after {
	content: '' !important;
	position: absolute !important;
	left: 0 !important;
	right: 0 !important;
	top: 0 !important;
	bottom: 0 !important;
	background: rgba(0, 0, 0, 0.1) !important;
	z-index: 2 !important;
	border-radius: 5px !important;
}

button {
	border-radius: 3px !important;
}

.noinputstyle .nav-pills button {
	display: inline-block !important;
	letter-spacing: 1px !important;
	padding: 0.5rem 1rem !important;
	border-radius: 5px !important;
	font-weight: 600 !important;
	cursor: pointer !important;
	transition: background-color 0.7s cubic-bezier(0.19, 1, 0.22, 1), transform 0.7s cubic-bezier(0.19, 1, 0.22, 1),
		box-shadow 0.7s cubic-bezier(0.19, 1, 0.22, 1) !important;
	border: none !important;
	color: var(--accent-light) !important;
	background-color: #fff !important;
	box-shadow: rgba(50, 50, 93, 0.11) 0px 4px 6px 0px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px !important;
	margin-top: 10px !important;
}
.noinputstyle .nav-pills button.active {
	background-color: var(--accent-light) !important;
	color: #fff !important;
}

.noinputstyle .nav-pills button:hover {
	background-color: var(--accent-light) !important;
	color: #fff !important;
	transform: translateY(-2px);
	box-shadow: rgba(50, 50, 93, 0.18) 0px 4px 12px 0px, rgba(0, 0, 0, 0.08) 0px 1px 6px 0px !important;
}
.noinputstyle .nav-pills button:active {
	background-color: var(--accent-light) !important;
	transform: translateY(0) !important;
	box-shadow: rgba(22, 22, 41, 0.15) 0px 4px 8px 4px, rgba(0, 0, 0, 0.1) 0px 1px 6px 2px !important;
	outline: none !important;
}
.noinputstyle .nav-pills button.danger:hover {
	background-color: #f5834e !important;
	color: #fff !important;
	transform: translateY(-2px);
	box-shadow: rgba(50, 50, 93, 0.18) 0px 4px 12px 0px, rgba(0, 0, 0, 0.08) 0px 1px 6px 0px !important;
}
.noinputstyle .nav-pills button.danger:active {
	background-color: #f5834e !important;
	transform: translateY(0) !important;
	box-shadow: rgba(22, 22, 41, 0.15) 0px 4px 8px 4px, rgba(0, 0, 0, 0.1) 0px 1px 6px 2px !important;
	outline: none !important;
}
.noinputstyle .nav-pills button:disabled {
	transform: none !important;
	box-shadow: none !important;
	position: relative !important;
	z-index: 1 !important;
	box-shadow: rgba(50, 50, 93, 0.11) 0px 4px 6px 0px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px !important;
	overflow: hidden !important;
}
.noinputstyle .nav-pills button.disabled::after {
	content: '' !important;
	position: absolute !important;
	left: 0 !important;
	right: 0 !important;
	top: 0 !important;
	bottom: 0 !important;
	background: rgba(0, 0, 0, 0.1) !important;
	z-index: 2 !important;
	border-radius: 5px !important;
}

/* .noinputstyle input:not([type='checkbox']):not([type='radio']),
.inputLabel {
	border: #ced4da 1px solid;
	background-color: white !important;
	border-radius: 0px !important;
	color: #6b7c93 !important;
	box-shadow: none !important;
	padding: 15px !important;
	white-space: nowrap;
} */

/* .noinputstyle select {
	border: #ced4da 1px solid !important;
	background-color: white !important;
	border-radius: 0px !important;
	color: #6b7c93 !important;
	padding: 15px !important;
	box-shadow: none !important;
	height: 100% !important;
} */

.sh-1 {
	box-shadow: 0 2px 4px rgba(112, 144, 176, 0.1), 0 4px 8px rgba(112, 144, 176, 0.12);
}

.sh-2 {
	box-shadow: 0 4px 8px rgba(112, 144, 176, 0.12), 0 8px 16px rgba(112, 144, 176, 0.16);
}

.sh-3 {
	box-shadow: 0 8px 16px rgba(112, 144, 176, 0.16), 0 16px 32px rgba(112, 144, 176, 0.2);
}

.sh-4 {
	box-shadow: 0 16px 32px rgba(112, 144, 176, 0.2), 0 20px 40px rgba(112, 144, 176, 0.25);
}

@media screen and (max-width: 786px) {
	.sh-1 {
		box-shadow: none;
	}
	.sh-2 {
		box-shadow: none;
	}
	.sh-3 {
		box-shadow: none;
	}
	.sh-4 {
		box-shadow: none;
	}
}

.rad-1 {
	border-radius: 0.5em !important;
}
.rad-t-1 {
	border-top-left-radius: 0.5em !important;
	border-top-right-radius: 0.5em !important;
}
.rad-2 {
	border-radius: 0.75em !important;
}
.rad-1 {
	border-radius: 1em !important;
}
.rad-4 {
	border-radius: 1.25em !important;
}
.rad-t-4 {
	border-top-left-radius: 1.25em !important;
	border-top-right-radius: 1.25em !important;
}
.rad-5 {
	border-radius: 1.5em !important;
}
.rad-6 {
	border-radius: 1.75em !important;
}
.rad-7 {
	border-radius: 2em !important;
}
.rad-8 {
	border-radius: 2.25em !important;
}

.bg-accent {
	background-color: var(--accent);
	color: var(--font);
}

.text-accent {
	color: var(--accent);
}
.bg-accent-gradient {
	min-height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	background: linear-gradient(177deg, var(--accent-light) 0%, var(--accent-light) 100%);
}

.btn-accent {
	background-color: var(--accent);
	color: var(--font);
}
.btn-accent:hover {
	background-color: var(--accent-dark);
	color: var(--font);
}
.btn-sec {
	border: 0.125em solid var(--accent);
}
.btn-sec:hover,
.btn-sec:active,
.btn-sec:focus {
	color: #000;
}

.btn-outline-accent {
	background-color: white;
	color: var(--accent);
	border: 1px solid var(--accent);
}
.btn-outline-accent:hover {
	color: white;
	background-color: var(--accent);
	border: 1px solid var(--accent);
}

.btn-accent:focus,
.btn-accent:active,
table .btn-accent.active {
	background-color: var(--accent);
	color: var(--font);
}

.btn-outline-accent:focus,
.btn-outline-accent:active,
table .btn-outline-accent.active {
	color: var(--accent);
	background-color: var(--font);
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

a.nope {
	color: inherit !important;
	text-decoration: none !important;
}

nav.navbar {
	padding: 5px 10px;
}

.hide {
	display: none;
}

@media screen and (max-width: 786px) {
	body {
		background-color: #fff;
	}
	.sh-1 {
		box-shadow: none !important;
	}
}

.navbar-toggler {
	border: 0;
}
.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: none !important;
}
.loader {
	color: var(--dotColor);
	font: 300 4em/150% Impact;
	text-align: center;
	--dotColor: var(--main);
}

.loader:after {
	content: ' .';
	width: 30px;
	height: 30px;
	animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
	0%,
	20% {
		color: rgba(0, 0, 0, 0);
		text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	40% {
		color: var(--dotColor);
		text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	60% {
		text-shadow: 0.25em 0 0 var(--dotColor), 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	80%,
	100% {
		text-shadow: 0.25em 0 0 var(--dotColor), 0.5em 0 0 var(--dotColor);
	}
}

table tbody tr a {
	cursor: pointer;
}

table tbody tr button.dropdown-toggle::after {
	display: none;
}
table tbody tr .dropup button.dropdown-toggle::after {
	display: none;
}
table tbody tr .dropstart button.dropdown-toggle::before {
	display: none;
}

.MuiPaper-root {
	box-shadow: none !important;
}
.MUIDataTableHeadCell-fixedHeader-77 {
	z-index: 0 !important;
}

.MUIDataTable-responsiveBase-5 {
	overflow-x: hidden !important;
}

.section-name {
	position: relative;
}
.section-name::after {
	position: absolute;
	content: '';
	display: block;
	background-color: var(--accent-light);
	width: 50px;
	height: 4px;
	border-radius: 5px;
	bottom: -10px;
}

.previewStudentTableValuesOtherHalf {
	position: relative;
}
.previewStudentTableValuesOtherHalf:not(:last-of-type)::after {
	content: ',';
	margin-right: 3.5px;
	display: inline-block;
}

.previewStudentTableMultipleValues > div.cell:not(:last-of-type) {
	border-right: 1px solid #33333333;
}

.previewStudentCardImageContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0;
	width: 100%;
}

.previewStudentCardImageHover {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 25px;
	width: 50px;
	background-color: var(--accent-darker);
	border: white 1px solid !important;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 3px 0px 3px;
	opacity: 0;
	transition: 0.25s;
}

.previewStudentCardImageContainer:hover .previewStudentCardImageHover {
	opacity: 1;
}

.tableNoScrollBarGlitch {
	overflow-x: hidden;
}

@media screen and (max-width: 786px) {
	.previewStudentCardImageHover {
		opacity: 1;
	}

	.tableNoScrollBarGlitch {
		overflow-x: auto;
	}
}

.MuiTableCell-head,
.MuiTableCell-root {
	z-index: 0 !important;
}

.onlyOnPrint {
	display: none;
}
@media print {
	#sidebar {
		display: none;
	}
	#healthdetailsfirstrow {
		border-top-color: transparent;
		padding: 0 !important;
	}
	#healthdetailsfirstrowtd {
		border-color: transparent;
		padding: 0 !important;
	}
	main#main {
		margin-left: 0px !important;
		margin-left: 0px !important;
		margin-right: 0px !important;
		margin-right: 0px !important;
		width: 100vw;
		padding: 0px !important;
	}
	.container {
		width: 100vw !important;
		padding: 0px !important;
	}
	.container-fluid {
		width: 100vw !important;
		padding: 0px !important;
	}
	.card.card-body.shadow {
		box-shadow: none !important;
		padding: 0 !important;
		border: none !important;
	}
	#healthdetailscard {
		page-break-before: avoid;
	}
	#healthdetailscard tr {
		page-break-inside: avoid;
		page-break-after: auto;
	}
	#studentdetailscard tr {
		page-break-inside: avoid;
		page-break-after: auto;
	}
	.doNotPrint {
		display: none;
	}
	.onlyOnPrint {
		display: block;
	}

	table,
	figure {
		page-break-inside: avoid;
	}
}
/*
@page {
    	size: A3 landscape;
		margin: 14px;
	}
*/

.homePageTabs button.nav-link {
	border: 1.35px solid #0d6efd;
	border-radius: 0px !important;
}
.homePageContent .address {
	width: 100%;
	height: 50vh;
}

/* .homePageLoginBox{
	position:fixed;
	right:0
} */
.notOnPhone {
	display: block;
}
.notOnDesktop {
	display: none;
}
.homepageIcons {
	width: 100px;
}

@media screen and (max-width: 786px) {
	.homePageLoginBox {
		position: relative;
		right: 0;
		width: 100%;
	}
	.notOnPhone {
		display: none;
	}
	.notOnDesktop {
		display: block;
	}
	.homepageIcons {
		width: 60px;
	}
}

.text-justify {
	text-align: justify;
}

.barChart {
	height: 210px;
	margin: -50px 0px 0px;
}
@media screen and (max-width: 786px) {
	.barChart {
		height: 240px;
	}
}

.loginBoxChoiceBtn {
	font-size: 14px !important;
}

.loginBoxChoiceBtn:hover {
	background-color: var(--accent-light) !important;
	color: white !important;
}
